// eslint-disable-next-line import/no-anonymous-default-export
export default {
  home: "الرئيسية",
  // ChauffeurTitle:
  //   "شوفير - حلك الأمثل لخدمات الشحن والتسوق المخصصة في الإمارات.",
  ChauffeurTitle:
    "أفضل تطبيق توصيل في الإمارات العربية المتحدة | خدمة سريعة وبأسعار معقولة | سائق",
  ChauffeurDescription:
    "استمتع بخدمات التوصيل السريعة وبأسعار معقولة في الإمارات العربية المتحدة. استخدم أفضل تطبيق chauffeur hub للحصول على خدمة موثوقة عند الطلب بأقل تكلفة في جميع أنحاء الإمارات العربية المتحدة!",
  aboutUs: "عن شوفير",
  send: "إرسال",
  follow: " تابع أخبارنا",
  followtitle: " تابع آخر الأخبار من خلال الاشتراك في نشرتنا",
  typeemail: " كتابة البريد الإلكتروني",
  thankyou: " شكراً لكم",
  thansuccsess: " تم إرسال طلبك بنجاح.",
  thancontact: " سننظر في الطلب ونعود إليك في أقرب وقت ممكن.",
  thanorderedSuccess: " تم استلام المبلغ الذي تم دفعه للطلب بنجاح",
  thanorderSuccessbtn: " متابعة الطلب",
  thannewsletterSuccess:
    " تم الاشتراك في النشرة الإخبارية على بريدك الإلكتروني",
  thanletterSuccessbtn: " الانتقال إلى الصفحة الرئيسية",
  blogs: "المدونات",
  NewPassword: " كلمة مرور جديدة",
  Message: "الرسالة",
  NotFound: "404: لم يتم العثور على الصفحة",
  NotFoundTitle: " تعذر علينا العثور على الصفحة التي تبحث عنها.",
  NotFoundText: "إليك بعض الخيارات للعودة إلى التصفح الصحيح:",
  NotFoundText2: "العودة إلى الصفحة الرئيسية",
  NotFoundText3: "اتصل بنا للمساعدة",
  slidertitle1: "ماذا يقول عملاؤنا عن Chauffeur",
  slidertitle2:
    "تطبيق يتميز بسهولة الاستخدام والأمان وتوفير جميع الخدمات بسرعة ودقة فائقة",
  videotitle: " شوفير هو مساعدك المحترف في كل ما يتعلق بخدمات الشحن والتوصيل",
  videotitle2:
    " التطبيق الأول من نوعه في الإمارات الذي يوفر لك خدمات شحن وتوصيل دقيقة وموثوقة بأسرع الطرق وأكثرها فعالية من حيث التكلفة.",
  ComplaintSuggestions: " الشكاوى والمقترحات",
  Createaccount: "إنشاء حساب",
  OrSignup: " قم بالتسجيل معنا",
  Signup: " اشترك الأن",
  Orlogin: " أو تسجيل الدخول",
  Login: "تسجيل دخول",
  Editprofile: " تعديل الملف الشخصي",
  Signin: " تسجيل دخول",
  EmailAddress: " البريد الإلكتروني",
  Password: " كلمة المرور",
  Account: "الحساب",
  done: "تم",
  deleteMsg:
    " يعد حذف حسابك أمرًا نهائيًا ولا يمكن التراجع عنه. سيتم مسح جميع بياناتك من أنظمتنا",
  settings: " الإعدادات",
  deleteAccount: "حذف الحساب",
  AppleStore: "متجر ابل",
  GooglePlay: "متجر جوجل",
  AppGallery: "متجر التطبيقات",
  Downloadfrom: "تحميل التطبيق من",
  DeleteConfirm:
    " للتأكيد، يُرجى إدخال رقم هاتف حسابك أدناه. يساعدنا هذا في التأكد من أنك أنت من يقوم بحذف حساب.",
  Pleaseentertransferamount: " الرجاء إدخال مبلغ التحويل",
  transferamount: " قيمة التحويل",
  ChauffeurCardNumber: "رقم بطاقة شوفير",
  ChauffeurCardNumberwallet: " يُرجى إدخال رقم بطاقة شوفير لشحن محفظتك",
  inputChauffeurCardNumber: "يُرجى إدخال رقم بطاقة شوفير الخاصة بك",
  ChargeWallet: " شحن المحفظة",
  SendMoney: " إرسال رصيد",
  WriteComment: " اضف تعليقاً",
  WriteCancelationReason: " اكتب سبب الإلغاء",
  NoBalancePleaseChargeYourWallet: " لا يوجد رصيد من فضلك اشحن محفظتك",
  Password8characters: " يجب أن تكون كلمة المرور أكبر من 8 رموز",
  Password20characters: " يجب أن تكون كلمة المرور أقل من 20 رمزًا",
  TelephoneNumber: " رقم الهاتف",
  Phonenumber: "رقم الهاتف",
  submit: "إرسال",
  "CancelationReason[SD1]": " سبب الإلغاء",
  RateThisDelivery: " قيم خدمة التوصيل",
  Ratedelivery: " تقييم خدمة التوصيل",
  SendVerificationCode: " إرسال رمز التحقق",
  Emailvalid: " عنوان البريد الإلكتروني غير صحيح",
  twopasswords: " كلمتا المرور التي أدخلتها غير متطابقتين!",
  pleaseselect: " يرجى الاختيار",
  Pleaseinput: " الرجاء الإدخال",
  ReenterPassword: " إعادة إدخال كلمة المرور",
  changepassword: "تغيير كلمة المرور",
  currentpassword: "كلمة المرور الحالية",
  newpassword: "كلمة المرور الجديدة",
  Thetwopasswords: " كلمتا المرور التي أدخلتها غير متطابقتين!",
  confirmpassword: "تأكيد كلمة المرور",
  Confirm: "تأكيد ",
  Forgetpassword: "نسيت كلمة المرور؟ ",
  FullName: "الاسم بالكامل ",
  Verifynow: " التحقق الآن",
  contactUs: "تواصل معنا",
  EditProfile: " تعديل الملف الشخصي",
  MyOrders: "طلباتي",
  MyAdresses: "عناويني",
  MyWallet: "محفظتى",
  Logout: "تسجيل خروج",
  Language: "اللغة",
  Delivery: "التوصيل",
  PersonalShopper: "المتسوق الشخصي",
  services: "الخدمات",
  footerAboutText: "شوفير يقربلك المسافات",
  footerAddress:
    "الطابق الخامس من مبنى (قرية الأعمال)B ديرة،   مكتب  رقم   S84",
  WeAreOnSocialMedia: " نحن على مواقع التواصل الاجتماعي",
  Shipping: "الشحن",
  RentCar: "التأجير ",
  "ShareRequirments[SD3]": " شارك متطلباتك",
  TermsAndCondition: " الشروط والأحكام",
  FAQ: "الأسئلة الشائعة",
  ReceiverNumber: " رقم المستلم",
  ComplaintAndSuggestions: "الشكاوى والمقترحات",
  Help: " المساعدة",
  Transferto: "تحويل إلى",
  SelectReceiver: " تحديد المستلم",
  Chat: "الدردشة",
  searchuser: "البحث عن المستخدم",
  Notification: "إشعارات",
  Searchreceiverphone: " ابحث عن هاتف المستلم",
  NoNotification: "لا يوجد إشعارات!",
  startchatwithdriver: " بدء الدردشة مع السائق",
  PrivacyPolicy: "سياسة الخصوصية",
  MyProfile: "ملفي",
  CopyrightsReservedFor: "حقوق النشر محفوظة لـ",
  chauffeurHub: "شوفير هاب",
  "curentYear[SD4]": "2024",
  Company: "الشركة",
  Profile: "الملف الشخصي",
  search: "البحث",
  startDate: "تاريخ البدء",
  endDate: "تاريخ الانتهاء",
  about: "عن شوفير",
  GettoknowChauffeur:
    " شوفير هو مساعدك المحترف وساعدك الأيمن في إنجاز وتنفيذ مهام الشحن والتوصيل. سواء كنت تستخدم التطبيق كفرد أو كشركة، فإننا نقدم لك خدمات توصيل متكاملة وحلولاً عملية تلبي احتياجاتك المختلفة بدءاً من توصيل الشحنات الصغيرة والشخصية وكذلك أغراض التسوق إلى نقل البضائع والمعدات في جميع أنحاء الإمارات. هنا شوفير في أي وقت حيث أن خدماتنا متوفرة 24/7. ",
  Chauffeurapp: "تطبيق شوفير ",
  bannerChauffeur:
    "شوفر هو أول تطبيق إماراتي للتوصيل يهدف إلى تسهيل حياتك ومساعدتك في إنجاز أعمالك بأقل تكلفة وبأقصى سرعة من خلال توفير تجربة توصيل موثوقة تتميز بأعلى معايير الجودة والاحترافية وتناسب الأفراد والشركات على حد سواء",
  chauffeur: "شوفير",
  learnMore: "لمعرفة المزيد",
  features: "مميزات",
  secureData: " بيانات آمنة",
  speedOfUse: "سرعة الاستخدام. ",
  easeOfUse: "تجربة استخدام سهلة. ",
  secureDataDesc:
    " نقدم لك جميع الخدمات والحلول التي تناسب احتياجاتك المختلفة.  ",
  speedOfUseDesc: " خدمات الاستلام والتوصيل عند الطلب، ما عليك سوى طلبها.",
  "easeOfUseDesc[SD5]":
    " نقرة واحدة، يمكنك بسهولة الحصول على كل ما تحتاجه بسهولة. ",
  clientsFeedback: " ملاحظات العملاء",
  downloadApp: " حمّل التطبيق",
  ourLocation: "موقعنا",
  whyChoose: "لماذا تختار",
  aSatisfiedCustomerIsBestForBusiness: " العميل الراضي هو الأفضل للأعمال ",
  team: "فريق",
  ourMail: "بريدنا",
  contact: "تواصل ",
  weAreOnSocialMedia: " نحن على مواقع التواصل الاجتماعي",
  us: "معنا",
  us2: "!معنا",
  Renting: "تأجير",
  termsAndConditions: " الشروط والأحكام",
  visionOf: " الرؤية",
  missionOf: " الرسالة",
  privacyPolicy: "سياسة الخصوصية",
  privacy: "الخصوصية",
  policy: "السياسة",
  signInTo: " سجّل الدخول إلى",
  rememberMe: "تذكرنى",
  dontHaveAnAccount: " لا تملك حساباً؟",
  signUpTo: " اشترك في",
  signUpChauffeurHubError:
    " البريد الإلكتروني الذي ينتهي بـ @chauffeurhub.com غير مسموح بها",
  termsAndConditions2: "الشروط والاحكام",
  acceptChauffeur: "قبول شوفير",
  haveAnAccount: "لديك حساب؟",
  consentToThisItemIsRequired: " يلزم الموافقة على هذا البند",
  contactMessage:
    " أدخل هاتف حسابك لإعادة تعيين كلمة المرور. ثم ستحتاج إلى استلام رمز التحقق. إذا واجهتك أي مشكلة بشأن إعادة تعيين كلمة المرور يرجى الاتصال بنا",
  verificationCode: "رمز التحقق",
  shipping: "الشحن",
  myOrders: "طلباتي",
  DoYouWantToCancelThisOrder: "? هل ترغب في إلغاء هذا الطلب",
  ok: "نعم",
  cancel: "إلغاء",
  deliveryService: "خدمة التوصيل",
  LE: "درهم",
  SAR: "درهم",
  from: "من",
  to: "إلى",
  deliveryInformation: "بيانات التوصيل",
  delivery: "التوصيل",
  orderInfo: "بيانات الطلب",
  deliveryType: "نوع التوصيل",
  products: "المنتجات",
  deliveryDate: "تاريخ التسليم او الوصول",
  deliveryNotes: "ملاحظات للتوصيل",
  deliveryCost: "تكلفة التوصيل",
  VAT: "رسوم الخدمه",
  subTotal: "المجموع الفرعي",
  total: "المجموع",
  Blogs: "المدونات",
  wtsText: " كيف يمكنني خدمتك؟",
  Individual: "فردي",
  Corporate: "شركة",
  ChauffeurDriver: "سائق",
  foundChauffeurlist: " لم يتم العثور على هذا الرقم في قائمة شوفير.",
  trackDelivery: "تتبع الشحنة",
  proceedToPayment: "متابعة الدفع",
  packageSize: " حجم الطرد",
  categoryType: "نوع الفئة",
  SendSMS: "أرسل رسالة ",
  doYouWantToDeleteThisAddress: " هل ترغب في حذف هذا العنوان؟",
  myAddresses: "عناويني",
  locationInfo: "بيانات الموقع",
  addNewAdress: "اضافة عنوان جديد",
  removeAdress: "حذف العنوان",
  orderDelivery: "تسليم الطلب",
  deliveryCompany: "شركة التوصيل",
  paymentOwner: "مالك الدفع",
  edit: "تعديل",
  confirm: "تأكيد",
  chargeWallet: "شحن المحفظة",
  charge: "شحن",
  wallet: "المحفظة",
  quantity: "الكمية",
  cardHolderName: "إسم صاحب البطاقة",
  AED: "درهم إماراتي",
  chauffeurCard: "بطاقة Chauffeur",
  bank: "البنك",
  paymentMethod: "طريقة الدفع",
  selectPayment: "اختر الدفع",
  method: "طريقة",
  onlinePayment: "الدفع عبر الإنترنت",
  addProduct: "إضافة ",
  Amount: "المبلغ المستحق",
  OopsAddress: "عفوا .. لم يتم العثور على عنوان!",
  deliveryLocation: "موقع التسليم",
  package: "الطرد",
  size: "الحجم",
  Code: "الرمز",
  type: "النوع",
  product: "المنتج",
  name: "الاسم",
  count: "العدد",
  otherProduct: "منتج آخر",
  startDeliver: "بدء التوصيل",
  enter: "ادخل",
  address: "العنوان",
  category: "الفئة",
  time: "الوقت",
  callUs: "اتصل بنا",
  nocompanyfound: " لم يتم العثور على عرض من شركات التوصيل لهذا الطلب.",
  Asanindividualorcompany:
    " تقدم لك خدمة شوفر خدمات شحن وتوصيل موثوقة بأقل تكلفة وبدون أي جهد، سواء كنت فرداً أو شركة",
  comingsoon: "قريبا",
  EnteryourOrderiD: "أدخل رقم طلبك",
  ReceiptImage: "صورة الإيصال",
  notes: " الملاحظات",
  orderName: "اسم الطلب",
  UploadReceiptImage: "تحميل صورة الإيصال",
  itemName: " اسم المنتج",
  NotpaidOrder: "طلب غير مدفوع",
  paidOrder: "طلب مدفوع",
  privacyDesc:
    " نهتم بخصوصيتك وأمنك بشكل كبير. ويتم جمع بعض البيانات لاستخدامها عن طريق الموقع من أجل توفير الخدمات عن طريق الموقع عبر الإنترنت، وسوف يقوم الموقع بتسجيل ومتابعة استخدامك للموقع من خلال استخدام، على سبيل المثال لا الحصر، الملفات النصية وغيرها من أدوات وأجهزة المتابعة.يخضع استخدام هذه البيانات لبنود سياسة الخصوصية التي نطبقها. وتعتبر سياسية الخصوصية جزءًا من هذه الشروط والأحكام ويجب أن تقرأها بالكامل. وفي حال عدم قبولك الشروط في سياسة الخصوصية، يجب عليك أن تمتنع عن استخدام موقع وتطبيق شوفير.نقر بالتزامنا بكافة تشريعات حماية الخصوصية وقت صدورها فيما يتعلق بأي معلومات شخصية تتعلق بك قمنا نحن بجمعها، وقد يطلب منك أن تدخل معلومات عن نفسك على صفحات مختلفة على موقع وتطبيق شوفير،  ونحن نلتزم بعدم استخدام هذه المعلومات التي زودتنا بها لأي غرض لم يتم إدراجه في سياسة الخصوصية ولغير الغرض الذي يحدده كل موقع وتطبيق حيث يتم طلب مثل هذه المعلومات.تحكم سياسة الخصوصية الأسلوب الذي تقوم به  الشركة بجمع المعلومات واستخدامها والحفاظ عليها والتصريح بالمعلومات التي تم جمعها من قبل مستخدمي الموقع وتطبق سياسة الخصوصية هذه في التطبيق وكافة المنتجات والخدمات المقدمة من الشركة.",
  list1: " نعتمد أفضل معايير الجودة لضمان حصولك على أفضل خدمة",
  list2: " نقوم بتوصيلك بأفضل شركات الشحن التي تقدم خدمات موثوقة",
  list3: " نستمع إليك بعناية لفهم احتياجاتك ونساعدك بمهنية في حل مشاكلك.",
  list4:
    " يستقبل فريقنا استفساراتك ويقدم لك الدعم على مدار الساعة طوال أيام الأسبوع.",
  GettoknowChauffeur2:
    " بنقرة بسيطة على تطبيق السائق، يمكنك الحصول على جميع الخدمات التي تحتاجها. نحن نعمل على أن نجعلك تشعر بالأمان وعدم الإزعاج. لذلك، جمعنا كل الخدمات التي قد تحتاجها في مكان واحد بأعلى معايير الجودة والمصداقية والالتزام بالمواعيد. سلامتك وراحة بالك هي أولويتنا الرئيسية في شوفير. ",
  CouponCode: "رمز القسيمة",
  EnterCouponCode: "أدخل رمز القسيمة",
  Apply: "يتقدم",
  Discount: "تخفيض",
  CashOnDelivery: "الدفع عند الاستلام (10 درهم إضافية)",
  blogsTitle:
    "مدونات مركز السائق | خدمات التوصيل الاحترافية الإمارات العربية المتحدة",
  blogsDescription:
    "تعمق في مدونتنا للحصول على أفضل الأفكار حول خدمات التوصيل الاحترافية وتطبيقات التوصيل للشركات في الإمارات العربية المتحدة. دليلك الأمثل لخدمات التوصيل السريع.",
  contactUsTitle: "اتصل بنا لتوصيل تطبيق السائق في الإمارات العربية المتحدة",
  aboutUsTitle:
    "مركز السائق | تطبيق البريد السريع والتسليم الإمارات العربية المتحدة | تسليم سريع وآمن الإمارات العربية المتحدة",
  contactUsDescription:
    "تواصل معنا إذا كانت لديك أي استفسارات حول خدمات التوصيل عبر تطبيق السائق في الإمارات العربية المتحدة. نحن هنا لمساعدتك بالدعم الفوري!",
  aboutUsDescription:
    "اكتشف Chauffeur Hub للتوصيل السريع والآمن في الإمارات العربية المتحدة. خدمات البريد السريع والتسليم الرائدة مع تتبع. أفضل تطبيق التوصيل للشركات.",
};
