// import { combineReducers } from "redux";
// import carts from "./reducers/carts";
// import products from "./reducers/products";
// import user from "./reducers/user";
// import { persistReducer } from 'redux-persist'
// const persistConfig = {
//     key: 'root',
//     storage,
//     whitelist : ['carts', 'user']
// }

// const rootReducer = combineReducers({
//     carts, user, products
// });

// export default persistReducer(persistConfig, rootReducer);

import { combineReducers } from "redux";
import carts from "./reducers/carts";
import products from "./reducers/products";
import user from "./reducers/user";

export default combineReducers({ carts, user, products });

