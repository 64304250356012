import { Image, Menu } from "antd";
import React from "react";

import user_img from "../../public/images/user, account, people, man.png";
import home_img from "../../public/images/home.png";
import pormotions_img from "../../public/images/promotions.png";
import search_img from "../../public/images/search.png";
import notification_img from "../../public/images/notification.png";
import wallet_img from "../../public/images/wallet.svg";
import address_img from "../../public/images/address.svg";
import order_img from "../../public/images/order.svg";

import styles from "./NavBottom.module.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ar from "../../public/localization/ar";
import en from "../../public/localization/en";

function NavBottom() {
  const [current, setCurrent] = React.useState("home");
  const { currentUser, currentLang } = useSelector(
    (state) => state?.entities?.user
  );
  const t = currentLang === "ar" ? ar : en;

  const items = [
    {
      label: t.Profile,
      key: "user",
      link: currentUser ? "/profile" : "/login",
      icon: <Image src={user_img} alt="user logo" preview={false} />,
    },
    {
      label: t.MyOrders,
      key: "Orders",
      link: currentUser ? "/orders" : "/login",
      icon: <Image src={order_img} alt="user logo" preview={false} />,
    },
    {
      label: t.home,
      key: "home",
      link: "/",
      icon: <Image src={home_img} alt="user logo" preview={false} />,
      // icon: <FaHome />,
    },
    {
      label: t.MyWallet,
      key: "wallet",
      link: currentUser ? "/my-wallet" : "/login",
      icon: <Image src={wallet_img} alt="user logo" preview={false} />,
    },
    {
      label: t.myAddresses,
      key: "Address",
      link: currentUser ? "/address" : "/login",
      icon: <Image src={address_img} alt="user logo" preview={false} />,
    },
  ];
  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };
  return (
    // <Menu
    //   onClick={onClick}
    //     selectedKeys={[current]}
    //   mode="horizontal"
    //   items={items}
    //   className={styles.menu}
    // />
    <Menu mode="horizontal" className={styles.menu + " res-menu-bar"}>
      {items?.map((s) => (
        <Menu.Item key={s?.key} icon={s?.icon}>
          <Link to={s?.link || "/"}>{s?.label}</Link>
        </Menu.Item>
      ))}
    </Menu>
  );
}

export default NavBottom;
