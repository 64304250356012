import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    masterData: null,
    currentLang: "en",
    metaDataIndvidual: null,
    userAddress: [],
    notificationList: [],
    Countries: [],
    servicesList: null,
    FAQCategories: null,
    Settings: null,
    termsAndConditionsPages: null,
    loading: false,
  },
  reducers: {
    setCurrentUser: (user, action) => {
      user.currentUser = action.payload;
    },
    setCurrentLang: (user, action) => {
      user.currentLang = action.payload;
    },
    setMetaData: (user, action) => {
      user.metaData = action.payload;
    },
    setServicesData: (user, action) => {
      user.servicesList = action.payload;
    },
    setCountries: (user, action) => {
      user.Countries = action.payload;
    },
    setFAQCategories: (user, action) => {
      user.FAQCategories = action.payload;
    },
    setSettings: (user, action) => {
      user.Settings = action.payload;
    },
    setTermsAndConditionsPages: (user, action) => {
      user.termsAndConditionsPages = action.payload;
    },
    setMetaDataIndividual: (user, action) => {
      const { type, data } = action.payload;
      console.log(type, "typetypetype");
      console.log(data, "datadatadatadata");
      // const typeKey = type;
      user.metaDataIndividual = { [type]: data };
    },
    setNotificationList: (user, action) => {
      user.notificationList = action.payload;
    },
    setCustomerAddresses: (user, action) => {
      user.userAddress = action.payload;
    },
  },
});

export const {
  setCurrentUser,
  setMetaData,
  setNotificationList,
  setCurrentLang,
  setServicesData,
  setMetaDataIndividual,
  setTermsAndConditionsPages,
  setCustomerAddresses,
  setSettings,
  setCountries,
  setFAQCategories,
} = slice.actions;

export default slice.reducer;
