import React from "react";
import styles from "../../../styles/pages/Home.module.scss";
import { Col, Image, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Api from "../../../public/network/ApiConfig";
import { useEffect } from "react";
import { setMetaData, setServicesData } from "../../../store/reducers/user";

export default function ServicesSection({ t }) {
  const { currentLang, servicesList } = useSelector(
    (state) => state?.entities?.user
  );
  // console.log(
  //   currentLang,
  //   "currentLangcurrentLangcurrentLangcurrentLangcurrentLang"
  // );
  // const [listData, setListData] = React.useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    Api.get("MetaData/GetServices").then(
      (res) => {
        console.log(res?.data?.model, "services");
        // setListData(res?.data?.model || []);
        dispatch(setServicesData(res?.data?.model));
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  return (
    <section className={styles.features_cards}>
      <div className="container">
        <div className={styles.features_cards_holder}>
          <h2 className={styles.features_cards_title}>
            <span className="color-primary">{t.chauffeur}</span>{" "}
            <span>{t.services}</span>
          </h2>
          <div className={styles.features_cards_content}>
            <Row gutter={[30, 40]} justify="center">
              {servicesList?.map((s) => (
                <Col
                  key={s?.Id}
                  xs={22}
                  sm={16}
                  md={12}
                  lg={8}
                  className={`${s?.Id === 3 && " event-none"}`}
                >
                  <Link
                    className={`${styles.features_cards_card} ${styles.service_card} bg-blur`}
                    to={{
                      pathname: "/" + currentLang + "/order-delivery",
                      state: { serviceId: s?.Id },
                    }}
                  >
                    <div className={styles.card_img}>
                      <Image src={s?.Image} alt="img" preview={false} />
                    </div>
                    <div className={styles.card_text}>
                      <h4>{currentLang === "en" ? s?.Name : s?.NameLT}</h4>
                      <p>
                        {currentLang === "en"
                          ? s?.Description
                          : s?.DescriptionLT}
                      </p>
                      {s?.Id === 3 && <p className="soon-p">{t.comingsoon}</p>}
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}
