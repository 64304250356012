import React, { useState } from "react";
import { Modal, Button } from "antd";
import { FaTimes } from "react-icons/fa";
import cls from "classnames";
import styles from "./OwnModal.module.scss";

function OwnModal({
  isModalVisible,
  onOk,
  onCancel,
  children,
  footer,
  width,
  closable,
  closeIcon,
  closeIconClass,
}) {
  return (
    <Modal
      title={null}
      closable={closable || false}
      visible={isModalVisible}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer || null}
      width={width}
      className={styles.own_modal}
      centered={true}
      // maskStyle={{ background: "#000", opacity: "0.83" }}
      closeIcon={
        closeIcon || (
          <FaTimes className={cls(styles.close_icon, closeIconClass)} />
        )
      }
      // bodyStyle={{ background: "blue" }}
      wrapClassName={styles.modal_wrapper}
      bodyStyle={{
        padding: "0",
      }}
    >
      <div className={styles.modal_inner}>{children}</div>
    </Modal>
  );
}

export default OwnModal;
