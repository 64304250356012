import React from "react";
import styles from "../../../styles/pages/Home.module.scss";
import { Col, Image, Row } from "antd";
import img_5 from "../../../public/images/surface1.svg";
import img_6 from "../../../public/images/speed.svg";
import img_7 from "../../../public/images/tap.svg";

export default function FeaturesSection({ t }) {
  return (
    <section className={styles.features_cards}>
      <div className="container">
        <div className={styles.features_cards_holder}>
          <h2 className={styles.features_cards_title}>
            <span className="color-primary">{t.chauffeur}</span>{" "}
            <span>{t.features}</span>
          </h2>
          <div className={styles.features_cards_content}>
            <Row gutter={[30, 40]} justify="center">
              <Col xs={22} sm={16} md={12} lg={8}>
                <div className={`${styles.features_cards_card} bg-blur`}>
                  <div className={styles.card_img}>
                    <Image src={img_5} alt="img" preview={false} />
                  </div>
                  <div className={styles.card_text}>
                    <h4>{t.secureData}</h4>
                    <p>{t.secureDataDesc}</p>
                  </div>
                </div>
              </Col>
              <Col xs={22} sm={16} md={12} lg={8}>
                <div className={`${styles.features_cards_card} bg-blur`}>
                  <div className={styles.card_img}>
                    <Image src={img_6} alt="img" preview={false} />
                  </div>
                  <div className={styles.card_text}>
                    <h4>{t.speedOfUse}</h4>
                    <p>{t.speedOfUseDesc}</p>
                  </div>
                </div>
              </Col>
              <Col xs={22} sm={16} md={12} lg={8}>
                <div className={`${styles.features_cards_card} bg-blur`}>
                  <div className={styles.card_img}>
                    <Image src={img_7} alt="img" preview={false} />
                  </div>
                  <div className={styles.card_text}>
                    <h4>{t.easeOfUse}</h4>
                    <p>{t.easeOfUseDesc}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}
