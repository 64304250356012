import React from "react";
import "./NewBanner.scss";
import hero5 from "../../public/images/hero5-app.png";
import QRCode from "../../public/images/hero5-app.png";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import ar from "../../public/localization/ar";
import en from "../../public/localization/en";
import { AiFillApple } from "react-icons/ai";
import { SiHuawei } from "react-icons/si";
import { FaGooglePlay } from "react-icons/fa";

export default function NewBanner() {
  const { currentLang } = useSelector((state) => state?.entities?.user);
  const t = currentLang === "ar" ? ar : en;

  return (
    <section className="banner-section-wrapper">
      <div className="background-image-wraper"></div>
      <div className="hero-bottom-shape-two"></div>
      <div className="container">
        <Row className="banner-text-content">
          <Col xs={24} lg={12}>
            <div className="hero-slider-content text-white py-5">
              <h1 className="text-white">{t.Chauffeurapp}</h1>
              <p className="lead">{t.bannerChauffeur}</p>
              {/* <div className="img-wrap">
                <img src={QRCode} alt="wa" />
              </div> */}
              <div className="action-btns mt-4">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <a
                      href="https://apps.apple.com/eg/app/chauffeur-hub/id1637732187"
                      target="_blank"
                      className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                      rel="noreferrer"
                    >
                      <AiFillApple />

                      <div className="download-text">
                        <small>{t.Downloadfrom}</small>

                        <h5 className="mb-0">{t.AppleStore}</h5>
                      </div>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.nativetech.chauffeur"
                      target="_blank"
                      rel="noreferrer"
                      className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                    >
                      <FaGooglePlay />
                      <div className="download-text">
                        <small>{t.Downloadfrom}</small>

                        <h5 className="mb-0">{t.GooglePlay}</h5>
                      </div>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://appgallery.huawei.com/app/C108194919"
                      target="_blank"
                      rel="noreferrer"
                      className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                    >
                      <SiHuawei />

                      <img
                        className="mr-3"
                        src="assets/img/icons8-huawei-logo-26.png"
                        alt=""
                      />
                      <div className="download-text">
                        <small>{t.Downloadfrom}</small>

                        <h5 className="mb-0">{t.AppGallery}</h5>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="img-wrap">
              <img
                src={hero5}
                alt="Shipping and shopping platform UAE"
                className="img-fluid"
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}
