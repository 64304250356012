import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import notFound from "../../public/images/notFound.jpg"
export default function Index({ t }) {
  const { currentLang } = useSelector((state) => state?.entities?.user);

  return (
    <div className="not-found-page">
        <div className="container">
        <div className="not-found">
        <h1>{t.NotFound}</h1>
        <p>{t.NotFoundTitle}</p>
        <p>{t.NotFoundText}</p>
        <ul>
          <li>
            <Link to={'/'}>
              {t.NotFoundText2}
            </Link>
          </li>
          <li>
            <Link to={'/' + currentLang + '/contact-us'}>
              {t.NotFoundText3}
            </Link>
          </li>
          {/* <li>Use the search bar above to find what you're looking for</li> */}
        </ul>
        <img src={notFound} alt="404 Not Found" />
        {/* <img src="/images/404.jpg" alt="404 Not Found" /> */}
      </div>
        </div>
     
    </div>
  );
}
