import { configureStore } from "@reduxjs/toolkit";
import api from "./middleware/api";
import reducer from "./rootReducer";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import carts from "./reducers/carts";
// import user from "./reducers/user";

const persistConfig = {
  key: 'root',
  storage,
  // whitelist: [carts, user],
};
const persistedReducer = persistReducer(persistConfig, reducer);

const store = 
  configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api),
  });

export default store;
