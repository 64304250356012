import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../middleware/apiActions";
const url = "/products";

const slice = createSlice({
  name: "products",
  initialState: {
    list: [],
    loading: false,
    // lastFeatch: null,
  },
  reducers: {
    productsRequested: (products, action) => {
      products.loading = true;
    },
    productsRequestFailed: (products, action) => {
      products.loading = false;
    },
    productsRecieved: (products, action) => {
      console.log(action.payload);
      products.list = action.payload;
    },
    productAdded: (products, action) => {
      products.list.push(action.payload);
    },
    productremoved: (products, action) => {
      return products.list.filter(
        (product) => product.id !== action.payload.id
      );
    },
  },
});

export const {
  productAdded,
  productremoved,
  productsRecieved,
  productsRequested,
  productsRequestFailed,
} = slice.actions;

export default slice.reducer;

export const loadProducts = () =>
  apiCallBegan({
    url,
    onStart: productsRequested.type,
    onSuccess: productsRecieved.type,
    onFailed: productsRequestFailed.type,
  });
