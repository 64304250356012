// import firebase from "firebase/app";
// import firebase from "firebase"sss
// import firebase from 'firebase/compat/app';
import { notification } from 'antd';
import { initializeApp } from 'firebase/app';


import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { FaBell } from 'react-icons/fa';
const firebaseConfig = {
  apiKey: "AIzaSyAEaOtxbdP6_Ik7Co7fGXDPeAVLIJETOvs",
  authDomain: "chauffeur-3a4d0.firebaseapp.com",
  projectId: "chauffeur-3a4d0",
  storageBucket: "chauffeur-3a4d0.appspot.com",
  messagingSenderId: "261156411298",
  appId: "1:261156411298:web:e94e1b73149f41d86c04fb",
  measurementId: "G-QVL00S2XPF"
};
 
initializeApp(firebaseConfig); 
const messaging = getMessaging();
export const requestForToken = () => {
  return getToken(messaging, { vapidKey: "BDNkYAY9vugvrNTn2K9sgM1qYwisrcAt3CMZd_z9RsqThqXK9Ba9lixVzPFZaWNLW6k6u_3uOfgjNCofihN8Bw8" })
    .then((currentToken) => {
      if (currentToken) { 
        // Perform any other neccessary action with the token
        return currentToken;
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = (onSuccessfcm) =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log(messaging)
      notification.info({
        message: payload?.data?.Description,
        // description: payload?.data?.Description,
        icon: <FaBell style={{ color: '#8c4191' }} />,
      });    
    onSuccessfcm(payload); 
    });
  });
