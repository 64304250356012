const slider = [
  {
    id: 1,
    title: "Rami",
    titleLT: "رامي",
    // title2: "Head Of Admin",
    // title2LT: "رئيس المشرف",
    textLT: "الموقع مفيد للغاية.. يحتوي على خدمات متنوعة ويغطى مناطق كثيرة.",
    text: "The website is very useful. It offers a variety of services and covers many locations.",
  },
  {
    id: 2,
    title: "John",
    titleLT: "جون",
    // title2: "Head Of Admin",
    // title2LT: "رئيس المشرف",
    textLT:
      "جربت عدة خدمات وكانت تجربة رائعة وخاصة فيما يتعلق بوقت التسليم والتكلفة. أنصح بالاشتراك في شوفير بشدة.",
    text: "I have tried several services and it was a great experience, especially in terms of delivery time and cost. I highly recommend subscribing to Chauffeur.",
  },
  {
    id: 3,
    title: "Lora",
    titleLT: "لورا",
    // title2: "Head Of Admin",
    // title2LT: "رئيس المشرف",
    textLT:
      "أعجبني أن الموقع يعمل بكل سلاسة وخطوات الطلب سهلة للغاية والأهم من ذلك هو الاهتمام بالتفاصيل المرفقة مع الطلبات.",
    text: "I liked how smoothly the website works and the ordering steps are very easy, and most importantly, they pay attention to the details attached to the orders.",
  },
  {
    id: 4,
    title: "Ahmed",
    titleLT: "أحمد",
    // title2: "Head Of Admin",
    // title2LT: "رئيس المشرف",
    textLT:
      "خدمة العملاء ممتازة ويردون على جميع الاستفسارات بصدر رحب ويحلون أي مشكلة باهتمام كبير.",
    text: "The customer service is excellent. They respond to all inquiries warmly and solve any issue with great care.",
  },
];
export default slider;
