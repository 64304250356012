import axios from "axios";
import { apiCallBegan, apiCallFailed, apiCallSuccess } from "./apiActions";
const baseURL = "https://fakestoreapi.com/";

const api =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== apiCallBegan.type) return next(action);

    const { url, method, data, onStart, onSuccess, onFailed } = action.payload;

    if (onStart) dispatch({ type: onStart });

    try {
      const respone = await axios.request({
        baseURL,
        url,
        method,
        data,
      });

      if (onSuccess) dispatch({ type: onSuccess, payload: await respone.data });
      dispatch(apiCallSuccess(await respone.data));
    } catch (error) {
      if (onFailed) dispatch({ type: onFailed, payload: error.message });
      dispatch(apiCallFailed(error.message));
    }

    next(action);
  };

export default api;
