import styles from "./SearchHero.module.scss";
import { Input, message } from "antd";
import { useState } from "react";
import Api from "../../public/network/ApiConfig";
import cls from "classnames";

const { Search } = Input;

function SearchHero({ placeholder, btnText, height, style, setSearchRes, addonBefore }) {
  const [loading, setLoading] = useState(false);
  const customStyle = { ...style };
  const onSearch = (value) => {
    if(!value) return ;
    setLoading(true);
    Api.get(`Customers/SearchInCustomersByNameOrPhone?Word=+971${value}`).then(
      (res) => {
        console.log(res);
        setLoading(false);
        setSearchRes(res?.data?.model || []);
      },
      (err) => {
        console.log(err);
        setLoading(false);
        message.error(err?.message);
      }
    );
  };
  customStyle["height"] = height ? height : "8.4rem";
  return (
    <Search
      placeholder={placeholder}
      addonBefore={addonBefore || false}
      allowClear
      enterButton={btnText || "Search"}
      size="large"
      onSearch={onSearch}
      style={customStyle}
      loading={loading}
      className={cls("search-hero", styles.search_hero)}
    />
  );
}

export default SearchHero;
